.register-occurrence-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow: auto;
    z-index: 1;

    &__content{
        padding: 40px 20px;

        &__header{
            position: relative;

            &__button{
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                padding: 0;
                border: none;
                background-color: transparent;

                svg{
                    path{
                        fill:#000000;
                    }
                }
            }

            & > button:hover{
                cursor: pointer;
            }

            & > p{
                text-align: center;
                color: #000000;
                margin-bottom: 35px;
            }

            &__title{
                font-weight: bold;
                font-size: 22px;
                line-height: 27px;
            }

            &__description{
                font-size: 12px;
                line-height: 18px;
                padding: 0px 15px;
            }
        }

        &__form{

            &__label{
                font-size: 14px;
                text-align: left;
                line-height: 27px;
                color: #000000;
                padding-left: 2px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap:10px;
            }

            &__input-field{
                margin-bottom: 40px;
                font-size: 12px;
            }

            &__submit-button{
                margin-top: 60px;
            }
        }
    }
}

.register-occurrence-page.center{
    display: flex;
    align-items: center;
    justify-content: center;
}