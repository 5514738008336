.accommodations{
    margin-top: 90px;
    
    &__content{

        .lds-ellipsis{

            div{
                
                background: #C3352D;
            }
        }

        &__empty{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-top: 20px;

            & > img{
                display: block;
                margin: auto;
                height: 45px;
            }

            & > p{
                margin-top: 20px;
                text-align: center;
                color: #DDDDDD;
                font-size: 14px;
                line-height: 18px;
                max-width: 200px;
            }

            i.ant-spin-dot-item{
                background-color: #C3352D;
            }
        }
    }

    &__button{
        display: block;
        margin: auto;
        padding: 0px;
        background-color: transparent;
        border: none;
        margin-bottom: 93px;
        color: #000000;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        text-decoration: underline;
    }

    &__button:hover{
        cursor: pointer;
        text-decoration: none;
    }
}