.presidents-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow: auto;
    z-index: 1;

    &__content{
        padding: 40px 20px 0px 20px;

        & > p{
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            color:#000000;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            & > img{
                width: 14px;
                height: 14px;
                margin-top: 5px;
                margin-right: 10px;
            }
        }

        & > p.title{
            font-weight: 800;
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
}