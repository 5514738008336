.informational-popover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &__popover {
        width: 220px;
        position: absolute;
        bottom: 40px;
        color: #7c7c7c;
        font-size: 12px;
        line-height: 18px;
        border-radius: 7px;
        text-transform: none;
        background-color: #ffffff;
        box-shadow: 0px 3px 24px rgb(0 0 0 / 10%);
        border: 1px solid #e8e8e8;
        padding: 16px;
        margin: 0 auto;
        left: -93px;

        p {
            text-align: center;
        }

        &__title {
            color: #000000;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        &__tip {
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            background-color: #ffffff;
            bottom: -10px;
            left: 50%;
            -webkit-transform: translateX(-50%)
                rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
        }
    }
}

.informational-popover:hover {
    cursor: pointer;
}