.file-uploader {
    margin-top: 35px;
    width: 100%;

    & > span {
        .ant-upload.ant-upload-drag,
        .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: none;
            background-color: #DDDDDD;
            display: block;
            margin: auto;
            padding: 0px;
        }
    }

    &__dragger.ant-upload.ant-upload-drag {
        border-radius: 7px;
        padding-top: 24px;
    }

    .ant-upload-drag-container{
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__dragger {
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__header {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__description{
            text-align: center;
            margin-top: 8px;
            font-size: 12px;
            line-height: 27px;
            color: #0A1638;
        }
    }

    &__content {
        width: 100%;

        &__list {
            width: 100%;

            &__item {
                width: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 10px 0px;
                padding: 10px 11px;
                border-radius: 7px;
                background-color: #FFFFFF;
                border-radius: 7px;
                border: 1px solid #E3E3E3;
                font-size: 14px;
                color: #000000;
                position: relative;

                &__header{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    &__icon{
                        background-color: #DDDDDD;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        
                        img{
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                & > div{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    & > div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &__size {
                    margin: 0px 10px;
                    padding-top: 2px;
                    
                    & > div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &__remove {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s ease-in-out;
                    position: relative;

                    & > img {
                        width: 15px;
                        height: 15px;
                        opacity: 1;
                    }

                    & > img:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0px !important;
}
