.register-success{
    width: 100%;
    padding: 0px 20px;

    & > img{
        display: block;
        margin: auto;
    }

    & > p{
        text-align: center;
        color:#000000;
    }

    &__title{
        display: block;
        margin: auto;
        max-width: 302px;
        margin-top:40px;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
    }

    &__description{
        display: block;
        margin: auto;
        max-width: 234px;
        line-height: 18px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 150px;
    }

    &__new-occurrence-button{
        margin:13px 0px 0px 0px;
        padding:0px;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #000000;
        font-weight: bold;
        font-size: 14px;
        text-decoration: underline;
    }

    &__new-occurrence-button:hover{
        cursor: pointer;
    }
}