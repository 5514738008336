.suggestions-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow: auto;
    z-index: 1;

    &__content{
        padding: 40px 20px 0px 20px;
        min-height: calc(100% - 144px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__form{
            & > p{
                color:#000000;
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
                margin-bottom: 30px;
                padding-left: 2px;
            }
        }

        &__button{
            margin-bottom: 25px;
        }
    }
}