.occurrence-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;

    &__content{
        display: flex;
        align-items: center;
        justify-content: center;

        .lds-ellipsis{

            div{
                
                background: #C3352D;
            }
        }

        &__empty{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            & > p{
                margin-top: 20px;
                text-align: center;
                color: #DDDDDD;
                font-size: 14px;
                line-height: 18px;
            }

            i.ant-spin-dot-item{
                background-color: #C3352D;
            }
        }

        &__occurrence{
            width: 100%;
            padding-top: 20px;

            &__block.no-border{
                border: none;
            }

            &__block.no-padding{
                padding: 0px 20px;
            }

            &__block.no-padding-bottom{
                padding: 20px 20px 0px 20px;
            }

            &__block{
                padding: 20px 20px;
                border-bottom: 1px solid #DDDDDD;
                color: #000000;

                &__title{
                    line-height: 27px;
                    font-weight: 800;
                    font-size: 18px;
                }

                &__description{
                    line-height: 27px;
                    font-size: 14px;
                }

                & > button{
                    margin: 0;
                    padding: 0;
                    border: none;
                    background-color: transparent;
                }

                & > button:hover{
                    cursor: pointer;
                }

                svg{
                    margin-bottom: 40px;
                    path{
                        fill:#000000;
                    }
                }

                &__status{
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline;

                    &__text{
                        color:#000000;

                        &__title{
                            line-height: 27px;
                            font-weight: 800;
                            font-size: 18px;
                        }

                        &__description{
                            line-height: 18px;
                            font-size: 12px;
                        }
                    }

                    &__circle{
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        border: 2px solid #FFFFFF;
                        margin-right: 5px;
                    }

                    &__circle.pending{
                        background-color: #F4D700;
                    }
        
                    &__circle.concluded{
                        background-color: #B6E388;
                    }
                }
            }
        }
    }
}