.occurrence-list-item{
    height: 70px;
    border-bottom: 1px solid #DDDDDD;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__content{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        &__left-column{
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 76px);
            position: relative;
            &__text{
                width: calc(100% - 61px);

                &__title{
                    font-weight: bold;
                    font-size: 16px;
                    color: #000000;
                }
    
                &__description{
                    font-size: 14px;
                    color:#BEBEBE;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
    
                &__title, &__description{
                    line-height: 20px;
                }
            }
    
            &__circle{
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background-color: #DDDDDD;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-right: 16px;
    
                &__status{
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 2px solid #FFFFFF;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
    
                &__status.pending{
                    background-color: #F4D700;
                }
    
                &__status.concluded{
                    background-color: #B6E388;
                }
            }
        }

        &__right-column{
            line-height: 27px;
            font-size: 14px;
            color: #BEBEBE;
        }
    }
}