.presidents-list-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 90px 20px 60px 20px;
    background-color: #FFFFFF;

    &__img{
        height: 202px;
        width: 202px;
        border-radius: 50%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-color: #FFFFFF;
        & > img{
            height: 202px;
            width: 202px;
            object-fit: cover;
        }
    }

    & > p{
        color: #000000;
        max-width: 300px;
        text-align: center;
    }

    &__title{
        line-height: 20px;
        margin: 10px 0px;
        font-weight: 800;
        font-size: 16px;
    }

    &__description{
        line-height: 20px;
        font-weight: bold;
        font-size: 14px;

        & > span{
            text-decoration: underline;
        }
    }
}

.presidents-list-item.grey{
    background-color: #EFEFEF;
}