.rent-a-car-list-item{
    position: relative;
    display: block;
    margin: auto;
    max-width: 600px;
    padding: 0px 20px 60px 20px;

    & > img{
        width:100%;
        border-radius: 7px;
        margin-bottom: 27px;
    }

    & > p{
        line-height: 24px;
        color: #000000;
        font-size: 14px;
    }

    &__title{
        font-weight: 800;
    }

    &__description{
        font-weight: bold;
    }
}