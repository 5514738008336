.footer{
    height: 100px;
    background-color: #FFFFFF;
    padding: 0px 50px;
    position:sticky;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 30px 10px rgb(0 0 0 / 13%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 998;

    &__new-occurrence-button{
        position: absolute;
        bottom: 105px;
        right: 10px;
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        border: none;
    }

    &__new-occurrence-button:hover{
        cursor: pointer;
    }

    &__tab{
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        border: none;

        &__tab-container{
            position: relative;

            & > p{
                font-weight: bold;
                font-size: 10px;
                color: #C7C7C7;
                line-height: 27px;
                margin: 0px;
                padding: 0px;
            }
            
            &__icon{
                position: relative;

                svg{
                    width: 30px;
                    height: 30px;
                    path{
                        fill: #C7C7C7;
                        transition: all 0.2s ease-in-out;
                    }
                }
    
                &__notification{
                    position: absolute;
                    top: 2px;
                    right: 30%;
                    width: 10px;
                    height: 10px;
                    background-color: #C3352D;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                }
            }

            &__marker{
                height: 3px;
                width: 0px;
                display: block;
                margin: auto;
                background-color: #C3352D;
                border-radius: 20px;
                transition: all 0.2s ease-in-out;
            }
        }
    }

    &__tab.active{

        .footer__tab__tab-container{

            & > p{
                color: #C3352D;
            }
            
            &__icon{

                svg{
                    path{
                        fill: #C3352D;
                    }
                }
            }

            &__marker{
                width: 85%;
                background-color: #C3352D;
            }
        }
    }

    &__tab:hover{
        cursor: pointer;
    }
}