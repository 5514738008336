.forgot-password-page {
    background-color: #FFFFFF;

    &__content{
        
        min-height: 100vh;
        padding: 0px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &__header{
            width: 100%;
            margin: 30px 0px;
            position: relative;
    
            &__button{
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                border: none;
                position: absolute;
                top: 0px;
                left: 0px;
    
                &__go-back-icon{
                    path{
                        fill: #000000;
                    }
                }
            }

            &__button:hover{
                cursor: pointer;
            }
    
            &__text{
                text-align: center;
                color: #000000;
                font-weight: 800;
                font-size: 20px;
                line-height: 27px;
            }
        }
    
        &__body{
            width: 100%;
    
            & > svg, & > img{
                display: block;
                margin: auto;
            }

            &__text{
                max-width: 232px;
            }

            &__confirm{
                max-width: 256px;
            }

            &__text, &__confirm{
                color: #000000;
                font-size: 12px;
                line-height: 22px;
                text-align: center;
                display: block;
                margin: 60px auto;
            }

            &__title{
                display: block;
                margin: auto;
                max-width: 271px;
                margin-top:40px;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                color:#000000;
                line-height: 27px;
            }

            &__label{
                font-size: 14px;
                text-align: left;
                line-height: 27px;
                color: #000000;
                padding-left: 2px;
            }
    
            &__input-field{
                font-size: 12px;
            }

            &__input-field.margin{
                margin-bottom: 40px;
            }
        }
    
        &__footer{
            width: 100%;
            margin: 40px 0px 30px 0px;
        }
    }

    &__success{
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 60px 20px 20px 20px;

        &__password-changed{
            width: 100%;

            &__body{
                & > img{
                    display: block;
                    margin: auto;
                }
        
                & > p{
                    text-align: center;
                    color:#000000;
                    line-height: 27px;
                }
        
                &__title{
                    display: block;
                    margin: auto;
                    max-width: 271px;
                    margin-top:40px;
                    font-weight: bold;
                    font-size: 22px;
                    margin-bottom: 150px;
                }
            }
        }
    }
}
