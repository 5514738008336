.notifications-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow: auto;
    z-index: 1;

    &__content{
        display: flex;
        align-items: center;
        justify-content: center;

        .lds-ellipsis{

            div{
                
                background: #C3352D;
            }
        }

        &__empty{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            margin-top: 20px;

            & > img{
                display: block;
                margin: auto;
                height: 45px;
            }

            & > p{
                margin-top: 20px;
                text-align: center;
                color: #DDDDDD;
                font-size: 14px;
                line-height: 18px;
                max-width: 200px;
            }

            i.ant-spin-dot-item{
                background-color: #C3352D;
            }
        }

        &__list{
            width: 100%;
        }
    }

    &__button{

        & > button{
            position: absolute;
            bottom: 0px;
            right: 0px;
            margin: 0px;
            padding: 0px;
            border: none;
            background-color: transparent;
        }

        & > button:hover{
            cursor: pointer;
        }
    }
}