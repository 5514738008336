.menu{
    background-color: #FFFFFF;
    position:sticky;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 30px 10px rgb(0 0 0 / 13%);
    z-index: 999;

    /*
    START- SCROLL-BAR style
    */
    ::-webkit-scrollbar {
        width: 0px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: none; 
        border-radius: 10px;
        border: none;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
        border: none;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: transparent; 
    }
    /*
    END- SCROLL-BAR style
    */

    &__content{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        padding: 0px 20px;

        &__logo{
            display: flex;
            align-items: center;
            justify-content: center;
            & > img{
                width: 31px;
                height: 37px;
                margin-right: 10px;
            }
    
            &__county{
                padding-left: 10px;
                border-left: 2px solid #DDDDDD;
        
                & > p{
                    margin: 0;
                    padding: 0;
                    font-size: 10px;
                    line-height: 10px;
                    color: #0A1638;
                }
        
                & > p:hover{
                    color: #0A1638;
                }
        
                .bold{
                    font-weight: 800;
                    font-size: 12px;
                    margin: 2px 0px;
                }
        
                .uppercase{
                    text-transform: uppercase;
                }
            }
        }
    
        &__hamburger-icon{
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: none;
        }
    
        &__hamburger-icon:hover{
            cursor: pointer;
        }
    }
}