.hamburger-menu{
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: #FFFFFF;
    width: 100%;
    height: 0px;
    min-height: 0px;
    transition: all 0.2s ease-in-out;

    p{
        width: 100%;
        height: 45px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #EFEFEF;
        color: #000000;
        font-size: 18px;
        font-weight: 800;
        line-height: 27px;
    }

    &__link.top-border{
        & > p {
            border-top: 1px solid #EFEFEF;
        }
    }

    &__link, &__button{

        & > p{
            background-color: #FFFFFF;
            padding-left: 50px;
            font-size: 16px;
            font-weight:600;
            border-bottom: 1px solid #EFEFEF;
        }

        & > p.last{
            border-bottom: none;
        }
    }

    &__sub-menu{

        &__link{

            & > p{
                background-color: #FFFFFF;
                padding-left: 80px;
                font-size: 16px;
                font-weight:600;
                color: #9D9D9D;
            }
        }
    }

    &__button.active{
        border: none;
    }

    &__button{
        width: 100%;
        margin: 0px;
        padding: 0px;
        background-color: #FFFFFF;
        border:none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EFEFEF;

        & > p {
            border: none;
        }

        & > img{
            margin-right: 50px;
            transform: rotate(0deg);
            transition: all 0.2s ease-in-out;
        }

        & > img.active{
            transform: rotate(180deg);
        }
    }

    &__button:hover{
        cursor: pointer;
    }
}

.hamburger-menu.active{
    min-height: calc(100vh - 190px);
}