.background{
    height: 100vh;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;

    &__page-container{
        height:100%;
        width: 800px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 2;

        @media only screen and (max-width: 800px) {
            width: 100%;
        }
    }

    & > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        opacity: 1;
    }
}