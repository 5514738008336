.login-page{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    min-height: 100vh;

    &__background{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 1;
    }

    &__spacer{
        margin-bottom: 20px;
    }

    &__form, &__header{
        z-index: 2;
        width: 60%;

        & > p{
            text-align: center;
            color: #FFFFFF;
            line-height: 32px;
        }

        &__title{
            font-size: 29px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__text{
            font-size: 24px;
            margin-bottom: 20px;
        }

        & > button{
            display: block;
        }

        &__login-button{
            margin-bottom: 15px;
        }
    }

    &__footer-message{
        font-size: 16px;
        margin: 20px 0px 20px 0px;
        color: #FFFFFF;
        line-height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:5px;
        z-index: 2;

        &__link {
            text-decoration: underline;
            color: #FFFFFF;
        }

        &__link:hover{
            text-decoration: none;
            color: #FFFFFF;
        }
    }
}