.page-banner{
    height: 144px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__button{
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 30px;
        left: 20px;
        z-index: 2;
    }

    &__button:hover{
        cursor:pointer;
    }

    & > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 1;
    }

    & > p{
        line-height: 27px;
        font-size: 26px;
        font-weight: bold;
        color: #FFFFFF;
        z-index: 2;
        width: 50%;
        display: block;
        margin: auto;
        text-align: center;
    }
}