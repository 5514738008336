.login-form{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    &__title{
        font-weight: bold;
        font-size: 22px;
    }

    &__spacer{
        margin-bottom: 20px;
    }

    & > p{
        text-align: center;
    }

    &__description{
        font-size: 12px;
        margin-bottom: 50px;
    }

    &__account-login{
        width: 100%;
        padding: 0px 21px;

        &__form{
            padding: 50px 0px 90px 0px;
    
            &__label{
                font-size: 14px;
                text-align: left;
                line-height: 27px;
                color: #000000;
                padding-left: 2px;
            }
    
            &__input-field{
                margin-bottom: 40px;
                font-size: 12px;
            }
    
            &__last-input-field{
                margin-bottom: 20px;
                font-size: 12px;
            }

            &__remember-section{
                display: flex;
                margin-bottom: 70px;
                align-items: center;
                font-size: 12px;
                justify-content: space-between;
                p, &__link {
                    margin-top: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                a {
                    text-decoration: underline;
                    font-weight: bold;
                    color: #C3352D;
                }
        
                a:hover{
                    text-decoration: none;
                    color: #C3352D;
                }
            }
    
            &__question{
                text-align: center;
                line-height: 27px;
                font-size: 12px;
                margin: 30px 0px;
            }
        }
    }

    &__login-footer-message{
        width: 100%;
        font-size: 16px;
        margin: 20px 0px 20px 0px;
        color: #000000;
        z-index: 2;
        line-height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:5px;

        &__link {
            text-decoration: underline;
            color: #000000;
            font-weight: bold;
        }

        &__link:hover{
            text-decoration: none;
            color: #000000;
        }
    }
}