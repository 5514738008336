.swipeable-carousel{
    position: relative;

    &__container{
        margin-top:10px;
        margin-bottom: 30px;
        display: flex;
        width: fit-content;
        align-items: center;
        overflow-x: visible;
        gap: 10px;

        &__translater{
            width: 100%;
            overflow: hidden;
            display: flex;
            transition: transform 1s ease;

            &__container{
                margin: 0px;
                padding: 0px;
                border: none;
                background-color: transparent;

                &__element{
                    background-size: cover;
                    background-position: center;
                }
            }

            &__container:hover{
                cursor: pointer;
            }
        }
    }
}