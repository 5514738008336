.page404{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content{
        padding: 40px 20px;
        z-index: 1;

        & > p{
            text-align: center;
            max-width: 400px;
            font-weight: 600;
            color: #000000;
        }

        &__title{
            font-size: 80px;
            font-weight: bold;
            line-height: 80px;
        }

        &__sub-title{
            font-size: 30px;
            line-height: 27px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        &__description{
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

    & > img{
        width: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        opacity: 0.05;

        @media (max-width: 600px) {
            width: 90%;
        }
    }
}