.notification-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;

    &__content{
        display: flex;
        align-items: center;
        justify-content: center;

        .lds-ellipsis{

            div{
                
                background: #C3352D;
            }
        }

        &__empty{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            & > p{
                margin-top: 20px;
                text-align: center;
                color: #DDDDDD;
                font-size: 14px;
                line-height: 18px;
            }

            i.ant-spin-dot-item{
                background-color: #C3352D;
            }
        }

        &__notification{
            width: 100%;
            padding-top: 20px;

            svg{
                margin-bottom: 40px;
                path{
                    fill:#000000;
                }
            }

            &__block{
                padding: 20px 20px;
                color: #000000;

                & > button{
                    margin: 0;
                    padding: 0;
                    border: none;
                    background-color: transparent;
                }

                & > button:hover{
                    cursor: pointer;
                }

                &__header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 45px;

                    &__county{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        & > div{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #C3352D;
                            color: #FFFFFF;
                            font-size: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 15px;
                            font-weight: bold;
                        }
                    }
                }

                &__body{
                    width: 55%;
                    min-width: 271px;
                }

                p{
                    color:#000000;
                    line-height: 27px;
                    font-size: 14px;

                    .event-link{
                        color:#000000;
                        text-decoration: underline;
                    }

                    .event-link:hover{
                        text-decoration: none;
                    }
                }

                p.bold{
                    font-weight: 800;
                }

                p.medium{
                    font-weight: bold;
                }

                p.margin{
                    margin-bottom: 35px;
                }
            }
        }
    }
}