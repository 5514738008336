.places-to-visit-list-item{
    position: relative;
    display: block;
    margin: auto;
    padding: 40px 0px 60px 0px;

    & > img{
        object-fit: cover;
        width: 70%;
        height: 400px;
        border-radius: 7px;
        padding: 0px;
        display: block;
        margin: 40px auto 0px auto;
    }

    & > p{
        line-height: 24px;
        color: #000000;
        padding: 0px 20px;
    }
    
    &__title{
        font-weight: 800;
        font-size: 18px;
        line-height: 27px;
        font-weight: 800;
        margin-bottom: 16px;
    }

    &__description{
        margin-top: 20px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 20px;

        & > p{
            line-height: 20px;
            font-weight: bold;
            font-size: 14px;
            color: #000000;
            padding: 0px 20px;
        }
    }
}

.places-to-visit-list-item.grey{
    background-color: #EFEFEF;
}