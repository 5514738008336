.sign-up-page{
    min-height:100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 22px 20px 22px;
    color: #000000;

    &__creating-account{
        width: 100%;

        &__spacer{
            margin-bottom: 20px;
        }

        & > p{
            text-align: center;
            line-height: 27px;
        }
    
        &__title{
            font-weight: bold;
            font-size: 22px;
        }
    
        &__description{
            font-size: 12px;
        }
    
        &__form{
            padding: 50px 0px 90px 0px;
    
            &__label{
                font-size: 14px;
                text-align: left;
                line-height: 27px;
                color: #000000;
                padding-left: 2px;
            }
    
            &__input-field{
                margin-bottom: 40px;
                font-size: 12px;
            }
    
            &__last-input-field{
                margin-bottom: 20px;
                font-size: 12px;
            }
    
            &__input-field.checkbox{
    
                p {
                    margin-top: 3px;
                }
                a {
                    text-decoration: underline;
                    font-weight: bold;
                    color: #000000;
                }
        
                a:hover{
                    text-decoration: none;
                    color: #000000;
                }
            }
    
            &__question{
                text-align: center;
                line-height: 27px;
                font-size: 12px;
                margin: 30px 0px;
            }
        }
    
        &__footer{
            width: 100%;
            margin: 20px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap:5px;
            font-size: 14px;
    
            &__link {
                text-decoration: underline;
                font-weight: bold;
                color: #000000;
            }
    
            &__link:hover{
                text-decoration: none;
                color: #000000;
            }
        }
    }

    &__account-created{
        width: 100%;

        & > img{
            display: block;
            margin: auto;
        }

        & > p{
            text-align: center;
            color:#000000;
            line-height: 27px;
        }

        &__title{
            display: block;
            margin: auto;
            max-width: 220px;
            margin-top:40px;
            font-weight: bold;
            font-size: 22px;
        }

        &__description{
            font-size: 12px;
            margin-top: 10px;
            margin-bottom: 150px;
        }
    }
}