.settings-page{
    height:calc(100% - 190px);
    background-color: #FFFFFF;
    color: #0A1638;
    position: relative;
    overflow: auto;
    z-index: 1;

    &__content{

        input.ant-input[disabled] {
            color: #272727;
            background-color: #FFFFFF;
            border-color: #E3E3E3;
            box-shadow: none;
            cursor: not-allowed;
            opacity: 1;
        }

        padding: 40px 20px 0px 20px;
        min-height: calc(100% - 144px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__form{
            padding: 0px 0px 70px 0px;

            & > p{
                color:#000000;
                line-height: 27px;
                padding-left: 2px;
                text-align: left;
                font-size: 14px;
            }

            &__title{
                font-weight: bold;
                margin-bottom: 30px;
            }
    
            &__input-field{
                margin-bottom: 40px;
                font-size: 12px;
            }
    
            &__last-input-field{
                margin-bottom: 20px;
                font-size: 12px;
            }
        }

        &__button{
            margin-bottom: 25px;
        }
    }
}