.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

html {
    font-family: 'Nunito', sans-serif;
}

html body {
    font-family: 'Nunito', sans-serif;
}

html p {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}

div .linkwithus-input {
    border-radius: 7px;
    border-color: #E3E3E3;
    box-shadow: none;
    padding: 8px 15px 8px;
    height: 45px !important;
    color: #272727;
}

div .linkwithus-dropdown .ant-select-selector input {
    height: 45px !important;
}

div.linkwithus-dropdown.ant-select .ant-select-selector{
    padding-top: 2.5px;
    height: 45px !important;
}

div .linkwithus-text-area.linkwithus-input {
    height: 100px !important;
}

div .linkwithus-input.linkwithus-phone-input {
    padding: 0px;
}

.linkwithus-dropdown-popup .ant-select-item.ant-select-item-option-selected {
    background-color: #C3352D!important;
}

div.linkwithus-input-error-wrapper .linkwithus-input-error-wrapper-message svg {
    background: #C3352D;
    border-radius: 100%;
}

div.linkwithus-input-error-wrapper .linkwithus-input-error-wrapper-message svg g path:nth-child(2){
    stroke: #FFFFFF;
    fill: #FFFFFF;
}

div.linkwithus-input-error-wrapper .linkwithus-input-error-wrapper-message svg g path:nth-child(3){
    stroke: #FFFFFF;
    fill: #FFFFFF;
}

div i.ant-spin-dot-item{
    background-color: #C3352D;
}

/*
START- SCROLL-BAR style
*/
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #C3352D;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #C3352D; 
}
/*
END - SCROLL-BAR style
*/



.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
